module.exports = {
  name: 'Cell Tech For Less',
  shortName: 'Cell Tech',
  title: 'Cell Tech For Less - Save time & money, With Quality Phone Repair Service',
  logo: `${__dirname}/src/images/logo/logo.png`,
  logoBig: `${__dirname}/src/images/logo/logo.png`,
  siteUrl: 'https://hamiltonwireless.ca',
  keyword: 'Cell Tech For Less, mobile phone, phone, repair shop, mobile repair shop, laptop repair shop, desktop repair shop, macbook, macbook repair, electronic repair shop, repair moile phone, repair computer, repair macbook, tablet repair, ipad repair',
  description: 'Cell Tech For Less - Save time & money, With Quality Phone Repair Service, We bring professionalism, good service & trust to the Phone repair service & maintenance business.',
  type: 'website',
  backgroundColor: '#f7a407',
  themeColor: '#030706',
  ogImage: `${__dirname}/src/images/logo/logo.png`,
};
