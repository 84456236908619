import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Header = () => (
  <StaticQuery
    query={graphql`
    query siteHeaderDetail {
      dataJson{
        logo{
          childImageSharp{
            fluid{
              ...GatsbyImageSharpFluid
          }
          }
        }
        phoneNumber
        email
        address{
          line1
          line2
        }
      }
    }
  `}
    render={(data) => {
      const { dataJson: { address, email, logo } } = data;
      return (
        <header className="header-area">
          <div className="container">
            <div className="row">
              <div className="col-xs-3 logo-parent">
                <div className="logo">
                  <Link to="/">
                    <Img
                     fluid={logo.childImageSharp.fluid}
                      alt="Cell Tech For Less"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-xs-9">
                <div className="header-contact-info">
                  <ul>
                    <li>
                      <div className="iocn-holder">
                        <span className="flaticon-home-page" />
                      </div>
                      <div className="text-holder">
                        <h5>{address.line1}</h5>
                        <h6>{address.line2}</h6>
                      </div>
                    </li>
                    <li>
                      <div className="iocn-holder">
                        <span className="flaticon-envelope" />
                      </div>
                      <div className="text-holder">
                        <h5>Mail Us</h5>
                        <h6><a href={`mailto:${email}`}>{email}</a></h6>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>
      );
    }}
  />
);

export default Header;
